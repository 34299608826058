/* global Common,$ */
/**
 * @file メッセージ関連処理
 * @author Ozaki Takao
 */
(function () {
  'use select';
  if (typeof window.Common === 'undefined') {
    window.Common = {};
  }
  /**
   * メッセージ定義
   */
  Common.MSG = {
    COMMON_ERROR_001: '{0}は必須入力です。',
    COMMON_ERROR_002: '{0}は必須選択です。',
    COMMON_ERROR_003: '{0}は{1}文字以内で入力して下さい。',
    COMMON_ERROR_004: '{0}の形式が正しくありません。',
    COMMON_ERROR_005: '{0}は全角で入力して下さい。',
    COMMON_ERROR_006: '{0}は半角で入力して下さい。',
    COMMON_ERROR_007: '{0}は半角英数字で入力して下さい。',
    COMMON_ERROR_008: '{0}は半角数字で入力して下さい。',
    COMMON_ERROR_009: '{0}と{1}の内容が一致しません。',
    COMMON_ERROR_010: '{0}の日付形式が正しくありません。',
    COMMON_ERROR_011: '{0}は半角カタカナで入力して下さい。',
    COMMON_ERROR_012: '{0}は全角カタカナで入力して下さい。',
    COMMON_ERROR_013: '{0}は全角英数字で入力して下さい。',
    COMMON_ERROR_014: '{0}の電話番号形式が正しくありません。',
    COMMON_ERROR_015: '{0}は{1}桁-{2}桁の範囲で入力して下さい。',
    COMMON_ERROR_016: '{0}は、{1}より大きい値を入力して下さい。',
    COMMON_ERROR_017: '{0}は正の数を入力して下さい。',
    COMMON_ERROR_018: '{0}は整数を入力して下さい。',
    COMMON_ERROR_019: '不正な操作が行われました。恐れ入りますが、再度ログインからやり直して下さい。',
    COMMON_ERROR_020: '不正な遷移です。ボタンの2重押しを控えて下さい。',
    COMMON_ERROR_021: 'タイムアウトとなりました。恐れ入りますが、再度ログインからやり直して下さい。',
    COMMON_ERROR_022: 'システムエラーが発生しました。',
    COMMON_WARN_023: '検索結果が{0}件を超えました。先頭{0}件のみ表示します。',
    COMMON_ERROR_024: '情報は既に更新されています。最新の情報を確認してください。',
    COMMON_ERROR_025: '不正な操作が行われました。',
    COMMON_ERROR_026: '{0}には半角スペース・改行・タブ文字を入力できません。',
    COMMON_ERROR_027: '{0}-{1}は全角で入力して下さい。',
    COMMON_ERROR_028: '{0}は、{1}文字で入力して下さい。',
    COMMON_ERROR_029: '{0}は半角数字、または小数点で入力してください。',
    COMMON_ERROR_030: 'クライアントの生成に失敗しました。',
    COMMON_ERROR_031: '{0}の取得でエラーが発生しました。',
    COMMON_ERROR_032: 'ファイルアップロード中にサーバでエラーが発生しました。再度実施してください。',
    COMMON_ERROR_033: '{0}の更新中にエラーが発生しました。再度実施してください。',
    COMMON_ERROR_034: 'S3へのファイル格納中にサーバでエラーが発生しました。再度実施してください。',
    COMMON_ERROR_035: '対象データが0件です。',
    COMMON_ERROR_036: '検索条件の開始/終了に同じ値、または、逆転した値は指定できません。',
    COMMON_ERROR_037: 'アクセスが集中しているため処理が失敗しました。しばらく時間をおいてから再度やり直して下さい。',
    COMMON_ERROR_038: '{0}は{1}で入力して下さい。',
    COMMON_ERROR_039: '{0}には{1}を使用できません。',
    COMMON_ERROR_040: '既に登録されているため、追加できません。',
    COMMON_ERROR_041: '{0}は{1}-{2}の範囲で入力して下さい。',
    COMMON_ERROR_042: '{0}のフォーマットが不正です。',
    COMMON_ERROR_043: '対応していないバージョンの{0}です。',
    COMMON_ERROR_044: 'サーバー内部の処理に時間がかかりすぎたため処理を中断しました。時間をおくか、データ件数を減らして再度操作してください。',
    COMMON_ERROR_045: 'タイムアウトとなりました。恐れ入りますが、再度ログインからやり直して下さい。{0}',
    COMMON_ERROR_101: '{0}に使用できない文字が含まれています。',
    COMMON_ERROR_114: '異なるユーザーグループの端末に対して本操作はできません。',
    COMMON_INFO_001: '対象データが0件です。',
    COMMON_INFO_002: 'ステータスの更新が完了しました。',
    COMMON_INFO_003: '最終接続日時の更新が完了しました。',
    COMMON_INFO_004: '該当する情報が見つかりませんでした。',
    COMMON_INFO_005: '削除します。よろしいですか？',
    COMMON_INFO_006: '完了しました。',
    COMMON_INFO_007: '登録します。よろしいですか？',
    COMMON_INFO_008: 'タスクを作成します。よろしいですか？',
    COMMON_INFO_009: '更新します。よろしいですか？',
    COMMON_INFO_010: '該当する情報が見つかりませんでした。{0}を表示します。',
    COMMON_INFO_011: '異なるユーザーグループの端末に対して処理を実行します。本当によろしいですか？',
    COMMON_INFO_012: '異なるユーザーグループ[{0}]の画面にアクセスしています。',
    LOGIN_ERROR_001: 'アカウントもしくはパスワードの入力内容をご確認ください。',
    SCHUP_ERROR_001: '利用できない拡張子です。',
    SCHUP_ERROR_002: 'スケジュールファイルの解析に失敗しました。',
    SCHUP_ERROR_003: 'スケジュールファイルの解析でエラーが発生しました。[schedule name]',
    SCHUP_ERROR_004: 'スケジュールファイルの解析でエラーが発生しました。[priority]',
    SCHUP_ERROR_005: 'スケジュールファイル名と受信したファイル名が一致しません。',
    SCHUP_ERROR_006: 'ファイルアップロード中にサーバでエラーが発生しました。再度実施してください。',
    SCHUP_ERROR_007: 'ファイルサイズが３MByteを超えています。',
    SCHUP_ERROR_008: 'スケジュールファイル名に使用できない文字が含まれています。',
    SCHUP_WARN_001: '既に登録済みの同名のファイルがありますが、アップロードしてよろしいですか？',
    SCHUP_INFO_001: 'スケジュールファイルのアップロードが完了しました。',
    SCHUP_INFO_002: 'スケジュールファイルのアップロードを開始します。よろしいですか？',
    SCHDWN_ERROR_001: 'スケジュールファイルの情報が取得できません。',
    SCHRELATION_ERROR_001: '端末とスケジュールの関連付けが失敗しました。再度実施してください。',
    SCHRELATION_INFO_001: '端末とスケジュールの関連付けが完了しました。',
    EVTCONFIRM_INFO_001: '端末に対してのイベント処理が有りません。',
    STATUSBEGIN_INFO_001: 'ステータスの更新が完了しました。',
    FILEDOWNLOAD_ERROR_001: '同期対象のイベント情報取得に失敗しました。',
    FILEDOWNLOAD_INFO_002: 'ダウンロードファイルがみつかりませんでした。',
    SCHLIST_ERROR_001: 'スケジュールリストが存在しません。',
    SCHLIST_ERROR_002: 'スケジュールリストの解析に失敗しました。',
    SCHLIST_ERROR_003: 'ファイルの格納先がありません。',
    SCHLIST_ERROR_004: 'S3へのファイル格納中にサーバでエラーが発生しました。再度実施してください。',
    SCHLIST_INFO_001: 'スケジュールリストアップロードが完了しました。',
    USER_ERROR_001: 'ユーザーグループの取得に失敗しました。',
    USER_ERROR_002: 'ユーザーグループの作成に失敗しました。',
    USER_ERROR_003: 'ユーザーグループ所属ユーザー情報の取得に失敗しました。',
    USER_ERROR_004: 'ユーザーグループの削除に失敗しました。',
    USER_ERROR_005: 'ユーザーグループの更新に失敗しました。',
    USER_ERROR_006: 'ユーザー種別の取得に失敗しました。',
    USER_ERROR_007: 'ユーザー詳細情報の取得に失敗しました。',
    USER_ERROR_008: 'ユーザー端末情報の取得に失敗しました。',
    USER_ERROR_009: '端末の追加に失敗しました。',
    USER_ERROR_010: 'ユーザーグループ所属端末情報の取得に失敗しました。',
    USER_ERROR_011: 'ユーザーの削除に失敗しました。',
    USER_ERROR_012: 'ログイン中のユーザーは削除できません。',
    USER_ERROR_013: '端末の新規追加ができませんでした。',
    USER_ERROR_014: 'テストメール送信でエラーが発生しました。再度実施してください。',
    USER_INFO_001: 'テストメールを送信します。よろしいですか？\n\n※メールが届くまでしばらく時間がかかる場合があります。しばらくしてもメールが届かない場合は、メールアドレスに誤りがないか確認してください。',
    USER_INFO_002: 'テストメールを送信しました。',
    USERGROUP_ERROR_001: '対象のユーザーグループにユーザーまたは端末が属しているため削除できません。',
    USERGROUP_ERROR_002: '以下端末は所属ユーザーグループを変更できませんでした。\n未実施もしくは同期中のタスクがあるため、タスク取消するかタスク完了した後、再度実施してください。\n\n{0}',
    USERGROUP_ERROR_003: '対象のユーザーグループ[{0}]が存在しません。',
    STOREGROUP_ERROR_001: '店舗グループの取得に失敗しました。',
    STOREGROUP_ERROR_002: '店舗グループの作成に失敗しました。',
    STOREGROUP_ERROR_003: '店舗グループの削除に失敗しました。',
    STOREGROUP_ERROR_004: '店舗グループの更新に失敗しました。',
    STOREGROUP_ERROR_005: '店舗グループ内に指定された端末が存在しません。',
    TERMINAL_ERROR_001: '端末情報の取得に失敗しました。',
    TERMINAL_ERROR_002: '端末情報の作成に失敗しました。',
    TERMINAL_ERROR_003: '端末情報の削除に失敗しました。',
    TERMINAL_ERROR_004: '端末情報の更新に失敗しました。',
    TERMINAL_ERROR_005: 'ダイレクトリストの取得に失敗しました。',
    TERMINAL_ERROR_006: 'ダイレクトリストの更新イベントの作成に失敗しました。',
    TERMINAL_ERROR_007: '番組ファイルの読み込みに失敗しました。',
    TERMINAL_ERROR_008: 'スケジュールリストの取得に失敗しました。',
    TERMINAL_ERROR_009: 'スケジュールリストの削除に失敗しました。',
    TERMINAL_ERROR_010: '端末の動作ログの取得に失敗しました。',
    TERMINAL_ERROR_011: '割当済み端末は{0}件までしか登録できません。',
    TERMINAL_ERROR_012: 'この端末は既に{0}ユーザーに登録されているため、登録できません。',
    TERMINAL_INFO_007: '放送を開始します。よろしいですか？',
    TERMINAL_INFO_008: '放送を停止します。よろしいですか？',
    TERMINAL_INFO_009: 'スケジュールを変更します。よろしいですか？',
    TERMINAL_INFO_010: 'スケジュールの変更を解除します。よろしいですか？',
    TERMINAL_INFO_011: '操作可能な端末がありません。',
    TERMINAL_INFO_012: '登録情報がありません。',
    TERMINAL_INFO_013: '端末にスケジュールを登録してください。',
    TERMINAL_INFO_014: '端末にダイレクト再生を登録してください。',
    TERMINAL_INFO_015: '実施前タスクはありません。',
    HOME_INFO_001: '過去3日間 タスクエラーはありません。',
    HOME_INFO_002: '過去3日間 端末状態通知はありません。',
    SCHEDULEFILE_ERROR_001: 'スケジュールファイル情報の取得に失敗しました。',
    SCHEDULEFILE_ERROR_002: 'スケジュールの配信に失敗しました。',
    SCHEDULEFILE_ERROR_003: 'スケジュールの削除に失敗しました。',
    SCHEDULEFILE_ERROR_004: 'スケジュールのアップロードに失敗しました。',
    SCHEDULEFILE_ERROR_005: 'スケジュールのダウンロードに失敗しました。',
    SCHEDULEFILE_ERROR_006: '配信する端末は200件までしか登録できません。',
    EVENT_ERROR_001: 'タスク情報の取得に失敗しました。',
    EVENT_ERROR_002: 'タスク削除に失敗しました。',
    EVENT_ERROR_003: 'タスク更新に失敗しました。',
    EVENT_ERROR_004: 'タスク詳細情報の取得に失敗しました。',
    DIRECT_ERROR_001: 'コンテンツファイルの取得に失敗しました。',
    DIRECT_ERROR_002: 'コンテンツファイルが存在しません。',
    DIRECT_ERROR_003: 'コンテンツファイルは200件までしか登録できません。',
    DIRECT_ERROR_004: '利用できない拡張子です。',
    DIRECT_INFO_001: '変更をキャンセルします。よろしいですか？',
    DIRECT_INFO_002: '変更内容がすべて破棄されます。よろしいですか？',
    DIRECT_INFO_003: '登録済みのダイレクト再生を解除します。よろしいですか？',
    DIRECT_INFO_004: '登録情報が見つかりませんでした。初期値で表示します。',
    CFGUP_ERROR_001: '設定ファイルが存在しません。',
    CFGUP_ERROR_002: '設定ファイルの解析に失敗しました。',
    CFGUP_ERROR_003: 'S3へのファイル格納中にサーバでエラーが発生しました。再度実施してください。',
    TASK_INFO_001: 'タスクの実行を取り消します。よろしいですか？',
    CONTENTS_INFO_001: '端末内の全コンテンツを削除します。\n\nスケジュールやダイレクト再生に関連のあるコンテンツについても削除され、再生中のスケジュールやダイレクト再生が停止する可能性があります。\n\nよろしいですか？',
    CONTENTS_ERROR_002: 'スケジュールファイル読み込みに失敗しました。',
    CONTENTS_ERROR_003: '番組ファイル読み込みに失敗しました。'
  };
  /**
   * メッセージ種別
   */
  Common.MSG_TYPE = {
    WARN: 'warn', // 警告
    CONFIRM: 'confirm', // 確認
    COMPLETE: 'complete' // 完了
  };
  /**
   * 確認メッセージ表示
   *
   * @access public
   * @param {string} msgStr メッセージ文言
   * @param {array} replaceStrs 置換文字列（配列）
   * @param {function} onOkClickFunc OK押下時コールバック
   * @param {function} onCancelClickFunc キャンセル押下時コールバック
   */
  Common.popupConfirmMessage = function (msgStr, replaceStrs, onOkClickFunc, onCancelClickFunc) {
    var str = msgStr;
    if (Array.isArray(replaceStrs)) {
      str = Common.stringFormat(msgStr, replaceStrs);
    }
    if (window.confirm(str)) {
      typeof onOkClickFunc === 'function' && onOkClickFunc();
    } else {
      typeof onCancelClickFunc === 'function' && onCancelClickFunc();
    }
  };
  /**
   * 完了メッセージ表示
   *
   * @access public
   * @param {string} msgStr メッセージ文言
   * @param {array} replaceStrs 置換文字列（配列）
   * @param {function} onCloseFunc クローズ時コールバック
   */
  Common.popupCompleteMessage = function (msgStr, replaceStrs, onCloseFunc) {
    var str = msgStr;
    if (Array.isArray(replaceStrs)) {
      str = Common.stringFormat(msgStr, replaceStrs);
    }
    window.alert(str);
    typeof onCloseFunc === 'function' && onCloseFunc();
  };
  /**
   * リスト０件メッセージ表示用の要素作成
   * ※作成した要素をリスト表示部に追加して表示する。
   * @access public
   */
  Common.createListEmptyMsgBlock = function () {
    // 0件メッセージ表示
    var $MessageBlock = $('<div class="box-msg-nothing-list" name="nothing-list-msg-error"></div>');
    $('<p>').text(Common.MSG.COMMON_INFO_004).appendTo($MessageBlock);
    return $MessageBlock;
  };
  /**
   * リスト０件メッセージ（「該当する情報が見つかりませんでした。」以外）表示用の要素作成
   * ※作成した要素をリスト表示部に追加して表示する。
   * @access public
   * @param {array} msgStrs メッセージ内容
   */
  Common.createListOtherEmptyMsgBlock = function (msgStrs) {
    if (!Array.isArray(msgStrs) || msgStrs.length === 0) {
      return;
    }
    // 0件メッセージ表示
    var $MessageBlock = $('<div class="box-msg-nothing-list" name="nothing-list-msg-error"></div>');
    msgStrs.forEach(function (msgInfo) {
      var msgEle = $('<p>').text(msgInfo.message);
      if (msgInfo.class) {
        msgEle.addClass(msgInfo.class);
      }
      msgEle.appendTo($MessageBlock);
    });
    return $MessageBlock;
  };
  /**
   * メッセージ表示（コンストラクタ）
   * @constructor
   * @param {string} id メッセージ挿入箇所のid
   */
  Common.MessageBlock = function (id) {
    this.$MessageBlock = $('#' + id);
  };
  Common.MessageBlock.prototype = {
    /**
     * メッセージ表示
     * @access public
     * @param {string} msgStr メッセージ文言
     * @param {array} replaceStrs 置換文字列（配列）
     */
    showMessage: function (msgStr, replaceStrs) {
      var that = this;
      if (!msgStr) {
        return;
      }
      var str = msgStr;
      if (Array.isArray(replaceStrs)) {
        str = Common.stringFormat(msgStr, replaceStrs);
      }
      that.msg = str;
      that.$MessageBlock.empty();
      $('<p>').text(str).appendTo(that.$MessageBlock);
      that.$MessageBlock.removeClass('s-hide');
    },
    /**
     * メッセージ表示(HTMLタグ使用)
     * @access public
     * @param {string} msgStr メッセージ文言
     * @param {array} replaceStrs 置換文字列（配列）
     */
    showMessageHtml: function (msgStr, replaceStrs) {
      var that = this;
      if (!msgStr) {
        return;
      }
      var str = msgStr;
      if (Array.isArray(replaceStrs)) {
        str = Common.stringFormat(msgStr, replaceStrs);
      }
      that.msg = str;
      that.$MessageBlock.empty();
      $('<p>').html(str).appendTo(that.$MessageBlock);
      that.$MessageBlock.removeClass('s-hide');
    },
    /**
     * 表示中のメッセージを取得する
     * @access public
     * @returns {string} メッセージ
     */
    getMessage: function () {
      var that = this;
      return typeof that.msg === 'string' ? that.msg : '';
    },
    /**
     * メッセージ非表示
     * @access public
     */
    hideMessage: function () {
      var that = this;
      that.$MessageBlock.addClass('s-hide');
      that.$MessageBlock.empty();
      that.msg = '';
    }
  };
}());
